@import "~@/styles/mediaQuery.scss";
@import "~@/styles/font-HelveticaNeueLTPro.scss";

.container {
  border-collapse: separate;
  border-spacing: 4px 4px;
  margin: 0 auto;
  width: 1100px;

  @include mediaXL {
    width: 1295px;
  }

  td,
  th {
    height: 84px;
  }

  th:nth-of-type(2) {
    height: 107px;
  }
}

.header {
  .line {
    p {
      border-radius: 18px 18px 0 0;
    }
    p:nth-of-type(2),
    p:nth-of-type(3),
    p:nth-of-type(4) {
      box-sizing: border-box;
      align-items: end;
      padding-bottom: 30px;
      font-weight: 600;
      font-size: 18px;
      position: relative;
      &::after {
        position: absolute;
        top: 12px;
        right: 16px;
        display: block;
        height: 65px;
        line-height: 65px;
        content: "A";
        color: rgba(255, 255, 255, 0.38);
        font-weight: 900;
        font-size: 65px;
        font-style: oblique;
        font-family: HelveticaNeueLTPro, sans-serif;
      }
    }
    p:nth-of-type(3) {
      height: 107px;

      &::after {
        content: "A";
      }
    }

    p:nth-of-type(4) {
      height: 147px;

      &::after {
        content: "C";
      }
    }
  }
}

.body {
  .line:last-of-type {
    p {
      border-radius: 0px 0px 18px 18px;
    }
  }

  .line:nth-of-type(1),
  .line:nth-of-type(2),
  .line:nth-of-type(3) {
    > p {
      height: 130px;
    }
  }
}

.line {
  display: flex;
  align-items: flex-end;

  > p {
    color: #ffffff;
    flex: 280px;
    height: 84px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 22px;
    text-align: center;
    @include mediaL{
      font-size: 14px;
    }
    @include mediaXL{
      font-size: 16px;
    }
  }

  > p:nth-of-type(1) {
    background: #f0f0f0;
    flex: 0 0 159px;
    margin-left: 0;
    color: #333;
    font-weight: 600;
  }

  > p:nth-of-type(2) {
    background: #FFBA69;
  }

  > p:nth-of-type(3) {
    background: #ff6666;
  }

  > p:nth-of-type(4) {
    background: #009cff;
  }
  ul {
    padding-left: 75px;
    display: flex;
    width: 100%;
    height: 100%;
    color: #fff;

    line-height: 22px;
    font-weight: 300;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    list-style: disc;
    @include mediaL{
      font-size: 14px;
    }
    @include mediaXL{
      font-size: 16px;
    }
    li{
      text-align: left;
    }
  }
}

.rocket {
  text-align: center;
  margin-top: -16px;
}

.content {
  margin-top: -20px;
}

.features {
  background: #f0f0f0 !important;
  color: #333 !important;
  span {
    margin-right: 40px;
    position: relative;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      height: 1px;
      background: #000;
      right: -44px;
      width: 50px;
      top: 50%;
    }
    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}
// 英文样式
// .locale-en{
//   .body {

//     .line:nth-of-type(3) {
//       > p {
//         padding: 0 20px;
//         box-sizing: border-box;
//         text-align: left;
//       }
//     }
//   }
// }
