@import '~@/styles/viewport.scss';
@import '~@/styles/color.scss';
@import '~@/styles/mediaQuery.scss';

.container {
  width: 1200px;
  margin: 12px auto 0;
  overflow: hidden;
  @include mediaXL {
    width: 1386px;
  }
  @include mediaS {
    width: 100%;
    padding-top: vm(40);
  }
  :global {
    .swiper-container {
      height: 800px;
      @include mediaS {
        height: vm(440);
      }
    }
    .swiper-slide {
      height: auto;
    }
    .swiper-container-multirow-column > .swiper-wrapper {
      flex-direction: row;
    }
  }
}

.swiper {
  width: 800px;
}

.card-box {
  padding: 28px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container {
  //width: 360px;
  //height: 332px;
  @include mediaS {
    width: 100%;
    height: vm(384);
    max-width: vm(331);
  }
}

.card {
  position: relative;
  background: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;

  width: 360px;
  @include mediaXL {
    width: 406px;
  }

  &:hover {
    .desc {
      opacity: 1;
    }
  }
  @include mediaS {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 vm(10) vm(10);
    width: auto;
  }
}

.nav {
  display: none;
  @include mediaS {
    display: flex;
  }
}

.nav-item {
  flex: 51px;
  margin-right: 5px;
  background: #f0f0f0;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 0 10px 0 0;
  &:last-of-type {
    margin-right: 0;
  }
}

.nav-active {
  background: #ff5353;
}

.card-content {
  padding-top: 10px;
  @include mediaS {
    padding-top: vm(20);
  }
}

.image-container {
  position: relative;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  width: 100%;
  height: 248px;
  @include mediaS {
    height: vm(166);
    order: 2;
    border-radius: 0 0 vm(10) vm(10);
  }
}

.title {
  padding-bottom: 36px;
  @include mediaS {
  }
}

.card-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 34px;
  padding-bottom: 6px;
  @include mediaS {
    flex-direction: row;
    color: #ff5353;
    font-size: vm(16);
    line-height: vm(22);
    font-weight: bold;
    padding-bottom: 0;
  }
}

.desc {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 44px;
  background: $red;
  color: #ffffff;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-weight: 300;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  opacity: 0;
  transition: all 0.3s;
  @include mediaS {
    position: sticky;
    opacity: 1;
    background: #ffffff;
    color: #333;
    font-size: vm(14);
    line-height: vm(22);
    font-weight: 400;
    box-shadow: none;
    min-height: vm(137);
    box-sizing: border-box;
    align-items: flex-start;
    padding: vm(8) vm(16) 0 vm(16);
  }
}
// 英文样式
.locale-en {
  :global {
    .swiper-container {
      @include mediaS {
        height: vm(450);
      }
    }
  }
  .card-box {
    padding: 28px 10px;
    @include mediaS {
      padding: vm(28) vm(10);
    }
  }
  .card {
    @include mediaS {
      min-height: vm(354);
    }
  }
  .card-container {
    @include mediaS {
      height: vm(394);
    }
  }

  .card-title {
    font-weight: normal;
    @include mediaL {
      padding: 0px 36px;
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      height: 84px;
      box-sizing: border-box;
    }
    @include mediaXL {
      font-size: 18px;
      line-height: 26px;
    }
    @include mediaS {
      text-align: center;
      font-size: vm(14);
      line-height: vm(17);
      width: 250px;
      margin: auto;
    }
  }
  .desc {
    font-weight: normal;
    @include mediaL {
      font-size: 18px;
      line-height: 26px;
      padding: 44px 24px;
    }
    @include mediaS {
      line-height: vm(17);
      padding: vm(8) vm(12) 0 vm(12);
    }
  }
  .card-content {
    @include mediaS {
      padding-top: vm(12);
    }
  }
}
