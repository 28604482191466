@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';
@import '~@/styles/font-FZLanTYJW.scss';
@import '~@/styles/font-HelveticaNeueLTPro.scss';

.container {
  position: relative;
  height: 440px;
  margin-top: 80px;
  overflow: hidden;
  @include mediaS {
    height: vm(251);
    background: #ff6666;
    margin-top: vm(10);
  }
}

.bg-container {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
}

.bg {
  height: 440px;
  width: 1920px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background: #ff6666;
  @include mediaS {
    width: vm(375);
    height: vm(251);
    margin: 0 auto;
  }
}

.title-container {
  position: relative;
  z-index: 1;
  width: 1130px;
  margin: 0 auto;
  @include mediaS {
    width: vm(300);
    text-align: center;
  }
}

.title {
  padding-top: 119px;
  width: 572px;
  font-size: 50px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.1em;
  font-family: FZLanTYJW, sans-serif;
  font-weight: bold;
  color: #ffffff;
  @include mediaS {
    margin: 0 auto;
    width: auto;
    padding-top: vm(35);
    font-size: vm(26);
    line-height: vm(34);
    font-weight: normal;
  }
}

.button-container {
  padding-top: 44px;
  padding-left: 230px;
  @include mediaS {
    position: absolute;
    padding-top: 0;
    padding-left: 0;
    right: vm(40);
    bottom: vm(-70);
  }
}

.button {
  letter-spacing: 0.1em;
  @include mediaS {
    width: vm(79);
    padding: 0;
    box-sizing: border-box;
    line-height: vm(16);
    padding: vm(5) 0;
  }
}

.ipFamily {
  position: absolute;
  left: 46.4%;
  z-index: 10;
  bottom: 0;
  width: 619px;
  height: 416px;
  @include mediaS {
    width: vm(210);
    height: vm(138);
    left: vm(20);
  }
}
// 英文样式
.locale-en {
  .title {
    font-family: HelveticaNeueLTPro, sans-serif;
    font-weight: 700;

    @include mediaL {
      padding-top: 67px;
      font-size: 50px;
      line-height: 60px;
      width: 618px;
      letter-spacing: 0.03em;
    }
    @include mediaS {
      font-size: vm(20);
      line-height: vm(24);
      letter-spacing: 0.05em;
      letter-spacing: initial;
    }
  }
  .button-container {
    @include mediaL {
      padding-top: 19px;
      padding-left: 189px;
    }
    @include mediaS {
      right: vm(-20);
      bottom: vm(-66);
    }
  }
  .title-container {
    @include mediaL {
      width: 1242px;
    }
  }
  .button {

    @include mediaL{
      letter-spacing: 0.03em;
      padding: 8px 17px 4px 17px;
    }
    @include mediaS {
      letter-spacing: initial;
      width: vm(167);
      padding: vm(6) 0 vm(3) 0;
    }
  }
}
