@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';
@import '~@/styles/font-HelveticaNeueLTPro.scss';

$width-xl: 416px;
$height-xl: 586px;

$width-l: 370px;
$height-l: $width-l * 1.41;

$width-s: vm(273);
$height-s: vm(363);

.box {
  padding: 20px 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: $width-l;
  height: $height-l;
  @include mediaXL {
    padding: 20px 8px;
    width: $width-xl;
    height: $height-xl;
  }

  @include mediaS {
    width: $width-s;
    height: $height-s;
    padding: vm(20);
    margin: 0 auto;
  }
}

.container {
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(240, 240, 240, 0.5);
    border-radius: 10px;
    transition: all 0.3s;
    pointer-events: none;
    @include mediaS {
      display: none;
    }
  }
}

.theme-red {
  .content {
    background: #ff6666;
  }
}

.theme-yellow {
  .content {
     background: #FFBA69;;
  }
}

.theme-blue {
  .content {
    background: #009cff;
  }
}

.picture {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  transition: all 0.3s;

  width: $width-l * 0.88;
  height: $width-l * 0.88 * 0.8;
  @include mediaXL {
    width: $width-xl * 0.88;
    height: 302px;
  }
  @include mediaS {
    width: $width-s;
    height: vm(185);
  }
}

.content {
  position: relative;
  box-sizing: border-box;
  padding: 17px 27px 0;
  border-radius: 0 0 10px 10px;
  color: #ffffff;
  transition: all 0.3s;

  width: $width-l * 0.88;
  height: $width-l * 0.88 * 0.584;
  @include mediaXL {
    width: $width-xl * 0.88;
    height: $width-xl * 0.88 * 0.584;
  }
  @include mediaS {
    width: $width-s;
    height: vm(167);
    padding: vm(16);
  }
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ffffff;
  transition: all 0.3s;
  @include mediaS {
    font-size: vm(18);
    line-height: vm(24);
    padding-bottom: vm(14);
  }
}

.list {
  padding-top: 6px;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  padding-left: 20px;
  list-style: disc;
  transition: all 0.3s;
  font-weight: 300;
  @include mediaS {
    font-size: vm(14);
    line-height: vm(19);
    padding-top: vm(13);
    padding-left: vm(20);
  }
}

.index {
  position: absolute;
  font-size: 36px;
  line-height: 42px;
  color: rgba(255, 255, 255, 0.1);
  font-style: italic;
  right: 35px;
  top: 20px;
  font-family: HelveticaNeueLTPro;
  font-weight: 900;
  @include mediaS {
    right: vm(17);
    top: vm(12);
  }
}

.active {
  padding: 0;

  &.container {
    &:after {
      background-color: rgba(255, 255, 255, 0);
    }
  }

  .picture {
    width: $width-l;
    height: $width-l * 0.8;
    @include mediaXL {
      width: $width-xl;
      height: 345px;
    }
    @include mediaS {
      width: $width-s;
      height: vm(185);
    }
  }

  .content {
    padding: 32px 30px;
    width: $width-l;
    height: $width-l * 0.684;
    @include mediaXL {
      width: $width-xl;
      height: $width-xl * 0.684;
    }
    @include mediaS {
      width: $width-s;
      height: vm(167);
      padding: vm(17);
    }
  }

  .title {
    padding-bottom: 20px;
    @include mediaS {
      padding-bottom: vm(14);
    }
  }

  .list {
    padding-top: 15px;
    @include mediaS {
      padding-top: vm(13);
      padding-left: vm(14);
    }
  }

  .index {
    right: 40px;
    top: 32px;
    @include mediaS {
      right: vm(17);
      top: vm(12);
    }
  }
}
// 英文样式
.locale-en {
  .box {
    @include mediaL {
    }
    @include mediaXL {
      height: 591px;
    }
  }

  .title {
    @include mediaS {
      font-size: vm(16);
      line-height: vm(19);
    }
    @include mediaL {
      width: 271px;
      font-size: 24px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-bottom: 15px;
    }
    @include mediaXL {
      width: 312px;
    }
  }

  .list {
    list-style: none;
    padding-left: 0;
    li {
      @include mediaL {
        width: 251px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 15px;
        padding-left: 20px;
        line-height: 22px;
      }
      @include mediaXL {
        width: 292px;
        font-size: 18px;
        line-height: 26px;
      }
      @include mediaS {
        font-size: vm(14);
        line-height: vm(17);
        padding-left: vm(14);
      }
      position: relative;
      &::after {
        position: absolute;
        content: ' ';
        display: block;
        border-radius: 100%;
        background: #fff;
        @include mediaL {
          top: 9px;
          left: 0;
          height: 6px;
          width: 6px;
        }
        @include mediaS {
          top: vm(3);
          left: 0;
          height: 5px;
          width: 5px;
        }
      }
    }
  }

  .picture {
    @include mediaS {
      width: vm(273);
      height: vm(166);
    }
  }
  .content {
    @include mediaS {
      height: vm(210);
      padding: vm(14);
    }
  }
  .container {
    transition: all 0.2s;
  }

  .active {
    .content {
      @include mediaL {
        padding: 22px 23px 31px 23px;
      }
    }
    .title {
      @include mediaL {
        width: auto;
        overflow: initial;
        white-space: initial;
        text-overflow: initial;
        line-height: 28px;
      }
    }
    .list {
      li {
        width: auto;
        overflow: initial;
        white-space: initial;
        text-overflow: initial;
      }
    }
    .picture {
      @include mediaS {
        width: vm(273);
        height: vm(166);
      }
    }
    &.theme-blue {
      .picture {
        @include mediaL {
          height: 225px;
        }
        @include mediaXL {
          width: 417px;
          height: 262px;
        }
      }
      .content {
        @include mediaL {
          height: 282px;
        }
        @include mediaXL {
          height: 329px;
        }
      }
    }
    &.theme-yellow {
      .picture {
        @include mediaL {
          height: 225px;
        }
        @include mediaXL {
          width: 417px;
          height: 262px;
        }
      }
      .content {
        @include mediaL {
          height: 282px;
        }
        @include mediaXL {
          height: 329px;
        }
      }
    }
  }
}
