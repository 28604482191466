@import "~@/styles/viewport.scss";
@import "~@/styles/mediaQuery.scss";

.container {
  width: 1276px;
  margin: 0 auto;
  padding-bottom: 120px;
  @include mediaS {
    width: vm(331);
    padding-bottom: vm(30);
  }
}

.titleContainer {
  position: relative;
  text-align: center;
  margin-bottom: 73px;

  &:after {
    content: " ";
    display: block;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    background: #000;
  }

  h2 {
    position: relative;
    z-index: 1;
    display: inline-block;
    font-size: 30px;
    line-height: 32px;
    padding: 0 147px;
    background: #ffffff;
    word-break: keep-all;
    white-space: nowrap;
    @include mediaS {
      font-size: vm(22);
      line-height: vm(22);
      padding: 0 vm(14);
    }
  }
  @include mediaS {
    width: vm(304);
    margin: auto;
    margin-bottom: vm(30);
  }
}

.group {
  @include mediaS {
  }
  @include mediaL {
    display: flex;
  }
}

.groupLeft {
  @include mediaS {
  }
  @include mediaL {
    width: 628px;
    margin-right: 48px;
    flex: 1;
  }
}

.leftItemImg {
  @include mediaS {
    width: vm(331);
    border-radius: vm(10);
    margin: 0 0 vm(4) 0;
  }
  @include mediaL {
    width: 628px;
    height: 353px;
    object-fit: cover;
    border-radius: 10px;
    // height: 360px;
    margin: 0 0 16px 0;
  }
}

.groupA {
  text-decoration: none;
  color: #333;
  &:last-child {
    .groupRightItem {
      border-bottom: none;
    }
  }
}

.groupRight {
  @include mediaS {
  }
  @include mediaL {
    flex: 1;
  }
}

.groupLeftItem {
  @include mediaS {
    border-bottom: vm(1) solid #E7E7E9;
    padding: 0 0 vm(16) 0;
  }
  @include mediaL {
  }
}

.groupRightItem {
  @include mediaS {
    border-bottom: vm(1) solid #E7E7E9;
    margin-top: vm(12);
    padding: 0 0 vm(16) 0;
  }
  @include mediaL {
    border-bottom: 1px solid #E7E7E9;
    margin-bottom: 24px;
    padding: 0 0 24px 0;
  }
}

.itemTop {
  display: flex;
  justify-content: space-between;
  @include mediaS {
    margin: 0 0 vm(8) 0;
    font-size: vm(14);
    line-height: vm(24);
  }
  @include mediaL {
    margin: 0 0 16px 0;
    weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
}

.categories {
  color: #ff5353;
}

.readTime {
  display: inline-block;
  color: #999999;
  @include mediaS {
    padding-left: vm(20);
    background: url("/images/home/read-time.svg") left center/vm(16) vm(16) no-repeat;
  }
  @include mediaL {
    padding-left: 20px;
    background: url("/images/home/read-time.svg") left center/16px 16px no-repeat;
  }
}

.blogTitle {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  @include mediaS {
    font-size: vm(16);
    line-height: vm(24);
  }
  @include mediaL {
    font-size: 24px;
    line-height: 36px;
  }
}

.blogExcerpt {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #999999;
  @include mediaS {
    font-size: vm(14);
    line-height: vm(24);
    margin-top: vm(4);
  }
  @include mediaL {
    font-size: 18px;
    line-height: 24px;
    margin: 8px 0 0 0;
  }
}

.more {
  display: block;
  cursor: pointer;
  text-align: center;
  color: #ff5353;
  font-family: sans-serif;
  text-decoration: none;
  @include mediaS {
    box-sizing: border-box;
    margin: auto;
    width: vm(185);
    height: vm(36);
    line-height: vm(32);
    border: 1px solid #ff5353;
    border-radius: vm(50);
  }
  @include mediaL {
    margin: 58px auto auto;
    width: 300px;
    height: 46px;
    border: 1px solid #ff5353;
    border-radius: 30px;
    font-size: 16px;
    line-height: 46px;
    &:hover {
      background: #ff5353;
      color: #ffffff;
    }
  }
}

.localeEn {
  .more {
    @include mediaS {
      line-height: vm(36);
    }
    font-family: HelveticaNeueLTPro, sans-serif;
  }
  .readTime {
    @include mediaS {
      background: url("/images/home/read-time.svg") left #{vm(3)}/ vm(16) vm(16) no-repeat;
    }
    @include mediaL {
      background: url("/images/home/read-time.svg") left 2px/16px 16px no-repeat;
    }
  }
}
