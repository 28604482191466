@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';
@import '~@/styles/config.scss';
@import '~@/styles/color.scss';
@import '~@/styles/font-HelveticaNeueLTPro.scss';
@import '~@/styles/font-FZLanTYJW.scss';

.container {
  position: relative;
  height: 675px;
  @include mediaXL {
    height: 987px;
  }
  @include mediaS {
    height: vm(590);
    overflow: hidden;
  }
}

.space-font {
  @include mediaL {
    display: inline-block;
    width: 1em;
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  bottom: 0;
  user-select: none;
  pointer-events: none;
  background: #ff5353;
}

.bg-text-edu,
.bg-text-cation {
  position: absolute;
  color: rgba(255, 255, 255, 0.3);
  font-family: HelveticaNeueLTPro, sans-serif;
  font-weight: 900;
  letter-spacing: 0.02em;
}
.formContainer {
  position: absolute;
  width: 320px;
  left: 65.5%;
  top: 23%;
  @include mediaXL {
    width: 440px;
  }
  @include mediaS {
    display: none;
  }
}
.bg-text-edu {
  left: 26%;
  top: 242px;
  font-size: 90px;
  line-height: 220px;
  opacity: 0.62;
  @include mediaXL {
    left: 23.7%;
    top: 511px;
    font-size: 150px;
    line-height: 220px;
  }
  @include mediaS {
    left: 13.7%;
    top: 41%;
    font-size: 90px;
    line-height: 220px;
    opacity: 0.32;
  }
}

.bg-text-cation {
  right: 21.3%;
  font-size: 90px;
  top: 83%;
  opacity: 0.62;
  @include mediaXL {
    right: 20.3%;
    top: 840px;
    font-size: 100px;
  }
  @include mediaS {
    left: 55%;
    top: 50%;
    font-size: 70px;
    line-height: 220px;
    opacity: 0.32;
  }
}

.pic {
  position: absolute;
  bottom: -56px;
  right: 0;
  height: 680px;
  pointer-events: none;
  user-select: none;
  width: 1106px;
  @include mediaXL {
    bottom: 0;
    width: 1617px;
    height: 916px;
  }
  @include mediaS {
    bottom: vm(-19);
    $height: vm(380);
    right: auto;
    left: vm(140);
    height: $height;
    width: $height * 1.65;
    transform: translateX(-43%);
  }
}
.picNew {
  position: absolute;
  bottom: 40px;
  width: 472px;
  height: 448px;
  left: 6%;
  @include mediaXL {
    bottom: 0;
    width: 612px;
    height: 579px;
    left: 17%;
  }
  @include mediaS {
    bottom: 9%;
    width: 422px;
    height: 363px;
    left: 28%;
  }
}
.ip {
  position: absolute;
  left: 11%;
  bottom: 71%;
  width: 110px;
  height: 160px;
  @include mediaXL {
    left: 12%;
    bottom: 59%;
    width: 150px;
    height: 217px;
  }
  @include mediaS {
    width: vm(106);
    height: vm(161);
    left: 9.6%;
    bottom: 52.6%;
  }
}
.ipHi {
  position: absolute;
  left: 35%;
  bottom: 156%;
  width: 89px;
  height: 68px;
  // animation: home-hi-s 2s;
  @include mediaXL {
    left: 35%;
    bottom: 142%;
    width: 129px;
    height: 118px;
    // animation: home-hi 2s;
  }
  @include mediaS {
    display: none;
  }
}

.pc {
  position: absolute;
  z-index: 1;
  width: 455px;
  height: 275px;
  left: -84px;
  bottom: -490px;
  @include mediaXL {
    width: 601px;
    height: 388px;
    left: -124px;
    bottom: -740px;
  }
  @include mediaS {
    width: vm(322);
    height: vm(196);
    bottom: vm(-420);
    left: vm(-25);
  }
}

$padding-top-xl: 174px;
$padding-top-l: 144px;

.title {
  position: relative;
  display: inline-block;
  font-size: 54px;
  line-height: 72px;
  color: #fff9eb;
  font-weight: bold;
  letter-spacing: 0.15em;
  white-space: pre-line;
  @include mediaXL {
    font-size: 68px;
    line-height: 90px;
  }
  @include mediaS {
    letter-spacing: -0.02em;
    font-size: vm(30);
    line-height: vm(40);
    position: relative;
    display: block;
    padding-left: vm(30);
    padding-right: vm(30);
    top: vm(-10);
  }
}

.titleGroup {
  position: relative;
  font-family: FZLanTYJW, sans-serif;
}

.title-container {
  position: relative;
  z-index: 1;
  width: 960px;
  margin: 0 auto;
  padding-top: $padding-top-l;
  @include mediaL {
    padding-top: 0;
    position: relative;
    bottom: 12px;
  }
  @include mediaXL {
    width: 1310px;
    padding-top: 5px;
  }
  @include mediaS {
    // width: vm(300);
    width: 100%;
    padding-top: vm(70);
  }
}

.sub-title {
  padding-top: 36px;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
  letter-spacing: 0.08em;
  @include mediaXL {
    font-size: 32px;
    line-height: 45px;
  }
  @include mediaS {
    padding-top: vm(6);
    font-size: vm(18);
    line-height: vm(22);
  }
}

.double-arrow {
  display: inline-block;
  vertical-align: top;
  padding-top: 7px;
  width: 20px;
  height: 20px;
  margin-left: 7px;
  @include mediaXL {
    padding-top: 10px;
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }
  @include mediaS {
    $size: vm(14);
    padding-top: vm(4);
    margin-left: vm(3);
    width: $size;
    height: $size;
  }
}

.button {
  letter-spacing: 0.3em;
  font-size: 18px;
  padding: 4px 14px 4px 18px;
  word-break: keep-all;
  white-space: nowrap;
  @include mediaXL {
    font-size: 22px;
    padding: 10px 35px;
  }
  @include mediaS {
    font-size: vm(14);
    line-height: vm(16);
    padding: vm(7) vm(18);
    letter-spacing: 0.1em;
  }
}

.button-container {
  padding-top: 36px;
  @include mediaXL {
    padding-top: 42px;
  }
  @include mediaS {
    padding-top: vm(30);
  }
}

.years-old {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 107px;
  height: 46px;
  padding: 0 0 0 14px;
  margin-top: 10px;
  margin-left: 24px;
  color: #9f2b1c;
  background: url('/images/home/popup-arrow-pc.svg') left top / cover no-repeat;
  position: absolute;
  top: 55px;
  right: 184px;
  letter-spacing: -0.01em;
  transform: translateX(100%);
  font-size: 24px;
  line-height: 46px;
  @include mediaXL {
    width: 153px;
    height: 66px;
    font-size: 36px;
    line-height: 67px;
    top: 38px;
    left: 206px;
    padding: 0 0 0 17px;
  }
  @include mediaS {
    width: vm(72);
    height: vm(36);
    font-size: vm(16);
    line-height: vm(30);
    padding: 0 0 0 vm(7);
    position: absolute;
    left: vm(90);
    transform: translateX(100%);
    top: vm(5);
    letter-spacing: 0;
    background: url('/images/home/popup-arrow-mobile.svg') left top / cover no-repeat;
    margin-top: vm(10);
  }
}
.harvard {
  position: absolute;
  z-index: 3;
  background: #ffffff;
  @include mediaL {
    width: 140px;
    height: 96px;
    border-radius: 0px 0px 20px 20px;
    right: -78px;
    top: 12px;
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 20px;
      position: absolute;
      background: linear-gradient(180deg, rgba(255, 69, 70, 0.5) 0%, rgba(255, 230, 230, 0) 100%);
      top: 0;
    }
  }
  @include mediaXL {
    width: 190px;
    height: 132px;
    border-radius: 0px 0px 20px 20px;
    right: 0;
    top: 0;
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 20px;
      position: absolute;
      background: linear-gradient(180deg, rgba(255, 69, 70, 0.5) 0%, rgba(255, 230, 230, 0) 100%);
      top: 0;
    }
  }
  @include mediaS {
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: vm(270);
    height: vm(58);
    border-radius: 0px 0px 12px 12px;
    display: flex;
    text-align: center;
    align-items: center;
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 12px;
      position: absolute;
      background: linear-gradient(180deg, rgba(255, 69, 70, 0.3) 0%, rgba(255, 230, 230, 0) 100%);
      top: 0;
    }
  }
}
.harvard-img {
  margin: auto;
  width: 118px;
  height: 54px;
  @include mediaXL {
    width: 164px;
    height: 76px;
  }
  @include mediaS {
    width: 108px;
    height: 50px;
  }
}

.harvard-text {
  color: #a5403e;
  white-space: pre-line;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  @include mediaXL {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
  }
  @include mediaS {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    padding: 0 16px 0 0;
    flex: 1;
  }
}
.lessons {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 249, 235, 1);
  margin-top: 16px;
  span {
    color: rgba(255, 249, 235, 0.4);
    margin-left: 12px;
    margin-right: 12px;
  }
  @include mediaS {
    margin-top: vm(8);
    font-size: vm(14);
    font-weight: 400;
    line-height: vm(20);
    letter-spacing: 0;
    text-align: left;
    position: relative;
    padding-left: vm(30);
    top: vm(-10);
  }
}
.lessons-zh {
  font-family: sans-serif;
}
// 英文样式
.locale-en {
  .lessons {
    font-size: 22px;
    @include mediaS {
      margin-top: vm(8);
      font-size: vm(13);
      font-weight: 400;
      line-height: vm(16);
      letter-spacing: 0;
      text-align: left;
      position: relative;
      top: vm(-20);
      span {
        margin-left: vm(6);
        margin-right: vm(6);
      }
    }
  }
  .titleGroup {
    font-family: HelveticaNeueLTPro, sans-serif;
  }
  .title {
    font-weight: 700;
    @include mediaL {
      letter-spacing: 0.03em;
      font-size: 54px;
    }
    @include mediaXL {
      font-size: 64px;
      line-height: 88px;
    }
    @include mediaS {
      letter-spacing: -0.02em;
      font-size: vm(26);
      line-height: vm(38);
      position: relative;
      display: block;
      padding-left: vm(30);
      padding-right: vm(30);
      top: vm(-20);
    }
  }
  .sub-title {
    display: none;
  }
  .formContainer {
    @include mediaS {
      display: none;
    }
  }
  .harvard {
    width: 180px;
    height: 100px;
    @include mediaXL {
      width: 180px;
      height: 120px;
    }
    @include mediaS {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: vm(259);
      height: vm(58);
      border-radius: 0px 0px vm(12) vm(12);
      display: flex;
      text-align: center;
      align-items: center;
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: vm(12);
        position: absolute;
        background: linear-gradient(180deg, rgba(255, 69, 70, 0.3) 0%, rgba(255, 230, 230, 0) 100%);
        top: 0;
      }
    }
  }
  .button {
    @include mediaL {
      letter-spacing: 0.03em;
      padding: 12px 19px 8px 19px;
      font-weight: 700;
      font-size: 16px;
    }
    @include mediaXL {
      font-size: 22px;
      padding: 18px 30px 18px 30px;
    }
    @include mediaS {
      font-size: vm(12);
      padding: vm(6) vm(14) vm(4) vm(14);
      font-weight: 700;
      letter-spacing: initial;
    }
  }
  .button-container {
    @include mediaS {
      padding-top: vm(12);
    }
  }
  .years-old {
    display: none;
    // background: url('/images/home/popup-arrow-pc-en.svg') left top / 99% no-repeat;
    // margin-left: 0;
    // right: 110px;
    // @include mediaL {
    //   width: 136px;
    //   height: 48px;
    //   right: 90px;
    // }
    // @include mediaXL {
    //   width: 192px;
    //   height: 68px;
    //   right: 110px;
    // }
    // @include mediaS {
    //   background: url('/images/home/popup-arrow-mobile-en.svg') left top / 99% no-repeat;
    //   width: vm(97);
    //   height: vm(39);
    //   top: vm(-21);
    //   left: vm(130);
    //   right: auto;
    //   line-height: vm(35);
    // }
  }
  .title-container {
    @include mediaS {
      width: 100%;
    }
  }
  .pc {
    position: absolute;
    z-index: 1;
    width: 455px;
    height: 275px;
    left: -84px;
    bottom: -492px;
    @include mediaXL {
      width: 601px;
      height: 388px;
      left: -124px;
      bottom: -740px;
    }
    @include mediaS {
      width: vm(322);
      height: vm(196);
      bottom: vm(-424);
      left: vm(-25);
    }
  }
  .ip {
    position: absolute;
    left: 11%;
    bottom: 71%;
    width: 110px;
    height: 160px;
    @include mediaXL {
      left: 12%;
      bottom: 60%;
      width: 150px;
      height: 217px;
    }
    @include mediaS {
      width: vm(106);
      height: vm(161);
      left: 9.6%;
      bottom: 52.6%;
    }
  }
  .picNew {
    position: absolute;
    bottom: 40px;
    width: 472px;
    height: 448px;
    left: 7%;
    @include mediaXL {
      bottom: -15px;
      width: 612px;
      height: 579px;
      left: 17%;
    }
    @include mediaS {
      bottom: 9%;
      width: 422px;
      height: 363px;
      left: 28%;
    }
  }
  .bg-text-edu {
    left: 26%;
    top: 242px;
    font-size: 90px;
    line-height: 220px;
    opacity: 0.62;
    @include mediaXL {
      left: 23.7%;
      top: 511px;
      font-size: 150px;
      line-height: 220px;
    }
    @include mediaS {
      left: 13.7%;
      top: 41%;
      font-size: 90px;
      line-height: 220px;
      opacity: 0.32;
    }
  }
  .harvard-text {
    font-family: HelveticaNeueLTPro, sans-serif;
    font-weight: normal;
    @include mediaL {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    @include mediaS {
      font-size: 12px;
      padding: 0 10px 0 0;
    }
  }
}

@keyframes home-hi {
  0% {
    opacity: 0;
    scale: (0.5);
  }
  100% {
    opacity: (1);
    scale: (1);
  }
}

@keyframes home-hi-s {
  0% {
    opacity: 0;
    scale: (0.8);
  }
  100% {
    opacity: (1);
    scale: (1);
  }
}
