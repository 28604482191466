@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';
@import '~@/styles/config.scss';
@import '~@/styles/color.scss';

.container {
  position: relative;
  box-sizing: border-box;
  padding-top: 154px;
  height: 880px;
  overflow: hidden;
  background: #f8fcff;
  @include mediaXL {
    height: 965px;
  }
  @include mediaS {
    padding-top: 0;
    height: auto;
    padding-bottom: vm(50);
  }
}

.content {
  width: 1280px;
  margin-left: auto;
  @include mediaL {
    width: 1670px;
  }
  @include mediaS {
    width: auto;
  }
}

.title-container {
  padding-left: 40px;
  padding-top: 20px;
  position: relative;
  @include mediaS {
    padding-left: vm(40);
    padding-top: vm(33);
    padding-right: vm(40);
  }
}

.title {
  color: $red;
  font-size: 30px;
  line-height: 42px;
  position: relative;
  font-weight: 600;
  white-space: pre-line;
  width: 440px;
  @include mediaS {
    font-size: vm(18);
    line-height: vm(24);
    letter-spacing: 0.02em;
    white-space: normal;
    width: 100%;
    p {
      line-height: vm(24);
    }
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    background: $red;
    width: 5px;
    top: 8px;
    left: -26px;
    bottom: 8px;
    border-radius: 10px;
    @include mediaS {
      display: none;
    }
  }
}

.desc {
  padding-top: 33px;
  font-size: 18px;
  width: 320px;
  line-height: 26px;
  font-weight: 300;
  @include mediaS {
    padding-top: vm(20);
    font-size: vm(14);
    line-height: vm(20);
    width: auto;
    font-weight: 400;
    letter-spacing: 0.04em;
  }
  @include mediaXL {
    width: 440px;
  }
}

.cards {
  position: absolute;
  top: 120px;

  right: -260px;
  width: 1140px;

  @include mediaXL {
    right: -200px;
    width: 1300px;
  }

  @include mediaS {
    //display: none;
    position: relative;
    padding-top: vm(16);
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    margin: 0 auto;
  }

  --swiper-theme-color: #{$red};
  :global {
    .swiper-pagination {
    }
    .swiper-pagination-bullet {
      width: 18px;
      height: 18px;
      @include mediaS {
        width: 10px;
        height: 10px;
      }
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 20px;
      @include mediaS {
        margin: 0 10px;
      }
    }
    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      @include mediaS {
        bottom: 0;
      }
    }
  }
}

.card {
  cursor: pointer;
  padding-bottom: 86px;
  @include mediaS {
    padding-bottom: vm(42);
  }
}
.img-container {
  position: absolute;
  @include mediaS {
    width: vm(102);
    height: vm(79);
    bottom: auto;
    left: auto;
    right: vm(-31);
    top: vm(-145);
  }
  @include mediaL {
    width: 462px;
    height: 356px;
    left: 0;
    bottom: -458px;
  }
  @include mediaXL {
    width: 652px;
    height: 502px;
    left: -125px;
    bottom: -530px;
  }
}
// 英文样式
.locale-en {
  .title {
    @include mediaS {
      font-size: vm(16);
      line-height: vm(19);
      font-weight: 700;
    }
    @include mediaL {
      font-size: 22px;
      line-height: 26px;
    }
    @include mediaXL {
      font-size: 28px;
      line-height: 42px;
    }
  }
  .img-container {
    @include mediaS {
      width: vm(83);
      height: vm(64);
      right: vm(-80);
      top: vm(-150);
    }
  }
  .desc {
    @include mediaS {
      font-size: vm(14);
      line-height: vm(17);
    }
  }
  .cards {
    @include mediaS {
      padding-top: 0;
    }
  }
}
