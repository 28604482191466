.container {
  margin: 110px 0;
}

.button-container {
  margin-top: 50px;
  text-align: center;
}

body .button {
  padding: 18px 50px;
  margin-right: 61px;
  transition: all 0.3s;
  &:last-of-type {
    margin-right: 0;
  }
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
}
// 英文样式
.locale-en {
  .button {
    font-size: 18px;
  }
}
