@import '~@/styles/viewport.scss';
@import '~@/styles/color.scss';
@import '~@/styles/mediaQuery.scss';

.container {
  position: relative;
  padding-top: 156px;
  padding-bottom: 58px;
  overflow: hidden;
  @include mediaS {
    padding-top: vm(60);
    padding-bottom: 0;
  }
}

.sub-title {
  margin-top: 12px;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.06em;

  b {
    color: #ff3939;
  }

  @include mediaS {
    font-size: vm(16);
    line-height: vm(22);
    margin-top: vm(16);
    padding: 0 vm(22);
  }
}

.title-container {
  position: relative;
  z-index: 2;
}

.card-container {
  position: relative;
  z-index: 2;
  margin: 6px auto 0;
  :global {
    --swiper-navigation-size: 38px;
    --swiper-theme-color: #{$red};
    @include mediaS {
      --swiper-navigation-size: #{vm(16)};
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 7%;
      background: url('/images/home/evaluation-arrow.svg') left top / contain no-repeat;
      &:after {
        display: none;
      }
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 7%;
      background: url('/images/home/evaluation-arrow.svg') left top / contain no-repeat;
      transform: rotateY(180deg);
      &:after {
        display: none;
      }
    }
  }
}

.card-item {
  padding: 80px 0;
  @include mediaS {
    padding: vm(16) 0;
  }
}

.bg {
  top: 12px;
  position: absolute;
  right: -50px;
  @include mediaS {
    display: none;
  }
}
.trustpilot{
  @include mediaL {
    width: 1240px;
    margin: 40px auto;
  }
  @include mediaXL {
    width: 1295px;
    margin: 50px auto;
  }
  @include mediaS {
    width: vm(320);
    margin: vm(20) auto;
    iframe{
      height: 290px !important;
    }
  }
}
// 英文样式
.locale-en {
  .sub-title {

    @include mediaS{
      letter-spacing: initial;
    }
    @include mediaL {
      letter-spacing: 0.03em;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: inherit;
    }
  }
}
