@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';
@import '~@/styles/font-FZLanTYJW.scss';

.box {
  position: relative;
}
.overflow {
  overflow: hidden;
}
.chinese-circle {
  position: absolute;
  right: 0;
  top: -117px;
  width: 303px;
  height: 341px;
  background: url('/images/home/project-circle.svg');
  @include mediaS {
    width: vm(51);
    height: vm(101);
    right: 0;
    top: vm(-50);
    z-index: 3;
    background: url('/images/home/project-chinese-mobile-circle.svg');
    background-size: cover;
  }
}

.container {
  position: relative;
  overflow: hidden;
  height: 680px;
  @include mediaS {
    height: auto;
    padding-bottom: vm(154);
    overflow: visible;
    z-index: 2;
    background: #ff6666;
  }
}

.bg {
  position: absolute;
  width: 85%;
  height: 680px;
  @include mediaXL {
    width: 78%;
  }
  @include mediaS {
    display: none;
  }
}

.text {
  position: relative;
  z-index: 1;
  color: #ffffff;
}

.text-container {
  position: relative;
  box-sizing: border-box;
  width: 1280px;
  padding: 130px 40px 0;
  margin: 0 auto;
  @include mediaXL {
    width: 1510px;
    padding-top: 170px;
    padding-left: 130px;
  }
  @include mediaS {
    width: vm(300);
    padding: vm(44) 0 0 0;
  }
}

.title {
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 30px;
  line-height: 48px;
  font-weight: 600;
  h2 {
    white-space: pre-line;
  }
  @include mediaS {
    font-size: vm(18);
    line-height: vm(28);
    letter-spacing: 0.02em;
  }
}

.years-old {
  position: relative;
  font-size: 24px;
  margin-left: 26px;
  line-height: 30px;
  background: #ffffff;
  color: #ff6666;
  border-radius: 10px;
  padding: 7px 15px;
  box-shadow: 0px 2px 4px rgba(165, 24, 24, 0.25);
  &:after {
    content: ' ';
    position: absolute;
    bottom: -12px;
    left: -4px;
    height: 27px;
    width: 80px;
    background: url('/images/home/popup-arrow-red.png') left bottom / contain no-repeat;
    @include mediaS {
      display: none;
    }
  }
  @include mediaS {
    font-size: vm(16);
    line-height: vm(20);
    border-radius: vm(20);
    padding: vm(1) vm(8) vm(1) vm(8);
    background: transparent;
    color: #ffffff;
    border: vm(1) solid #ffffff;
    box-shadow: none;
    word-break: keep-all;
    white-space: nowrap;
    margin-left: vm(10);
  }
}

.content {
  margin-top: 30px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  width: 380px;
  @include mediaXL {
    width: 438px;
  }
  @include mediaS {
    font-size: vm(14);
    line-height: vm(20);
    height: auto;
    width: auto;
    margin-top: vm(10);
    letter-spacing: 0.04em;
  }
}

.button-container {
  margin-top: 20px;
  @include mediaS {
    margin-top: vm(43);
  }
}

.button {
  letter-spacing: 0.1em;
}

.preview {
  position: absolute;
  top: 80px;
  right: 0;
  width: 674px;
  height: 571px;
  @include mediaXL {
    right: 80px;
  }

  @include mediaS {
    position: absolute;
    margin-left: auto;
    width: vm(200);
    height: vm(169);
    top: auto;
    bottom: vm(-116);
    right: vm(-2);
    z-index: 2;
  }
}

.math {
  position: relative;
  top: -20px;
  @include mediaS {
    top: vm(-1);
    padding-bottom: 0;
    height: vm(364);
    background: #009cff;
  }

  .bg {
    margin-left: auto;
    right: 0;
  }

  .text-container {
    width: 1200px;
    padding-left: 0;
    @include mediaXL {
      padding-top: 180px;
      padding-right: 220px;
      width: 1400px;
    }
    @include mediaS {
      position: relative;
      top: vm(-5);
      width: vm(300);
      padding-top: 0;
    }
  }

  .text {
    width: 440px;
    margin-left: auto;
    @include mediaS {
      width: auto;
    }
  }

  .years-old {
    color: #009cff;
    @include mediaS {
      color: #ffffff;
    }
  }

  .content {
    // height: 140px;
  }

  .preview {
    width: 650px;
    height: 505px;
    left: 0;
    top: 110px;
    right: auto;
    @include mediaS {
      //display: none;
      width: vm(207);
      height: vm(161);
      top: 100%;
      margin-top: vm(-30);
      bottom: auto;
      left: vm(-10);
    }
  }

  .button-container {
    margin-top: 20px;
    padding-left: 4px;
    text-align: left;
    @include mediaS {
      text-align: right;
    }
  }
}

.line {
  display: none;
  @include mediaS {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: vm(74);
  }
}

.math-cookie {
  display: none;
  @include mediaS {
    display: block;
    position: absolute;
    right: 0;
    width: vm(59);
    height: vm(72);
    bottom: vm(35);
  }
}
// 英文样式
.locale-en {
  .text-container {
    padding: 110px 0 0 93px;
    @include mediaS {
      padding: vm(34) 0 0 0;
    }
  }
  .content {
    line-height: 1.3;
    @include mediaS {
      line-height: vm(17);
    }
    @include mediaL {
      width: 441px;
      margin-top: 11px;
    }
    @include mediaXL {
      width: 441px;
      margin-top: 20px;
      font-size: 18px;
    }
  }
  .years-old {
    margin: 0 0 24px;
    @include mediaS {
      font-size: vm(12);
      padding: vm(3) vm(8) vm(0) vm(8);
      border-radius: vm(50);
      margin-left: 0;
    }
  }
  .title {
    flex-direction: column-reverse;
    @include mediaS {
      font-size: vm(16);
      line-height: vm(19);
    }
  }
  .button {
    font-family: HelveticaNeueLTPro, sans-serif;
    font-weight: 700;
    letter-spacing: inherit;
    @include mediaS {
      font-size: vm(12);
      // line-height: vm(26);
      padding: vm(6) vm(10) vm(4) vm(10);
      letter-spacing: initial;
    }
    @include mediaL {
      font-size: 14px;
      padding: 12px 14px 9px 14px;

    }
    @include mediaXL {
      font-size: 18px;
      padding: 16px 29px 15px 29px;
    }

  }
  .preview {
    @include mediaS {
      width: vm(189);
      height: vm(150);
      top: auto;
      bottom: vm(-103);
      right: vm(-18);
    }
  }
  .math {
    height: vm(420);
    .text {
      @include mediaL {
        width: 512px;
        margin-right: -38px;
        position: relative;
        top: 84px;
      }
      @include mediaXL {
        width: 512px;
        margin-right: -80px;
        top: 100px;
      }
    }
    .content {
      @include mediaL {
        width: 477px;
      }
      @include mediaXL {
        width: 477px;
      }
    }
    .text-container {
      @include mediaL {
        padding: 50px 40px 0;
      }
      @include mediaXL {
        padding: 62px 220px 0 0;
      }
      @include mediaS {
        top: vm(-40);
      }
    }
    .preview {
      top: auto;
      bottom: vm(-114);
      right: auto;
    }
  }
}
