@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';

.box {
  width: 310px;
  min-height: 350px;
  position: relative;
  margin: 150px 0 65px 0;
  background: #fff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 33px;
  @include mediaS {
    width: vm(300);
    height: vm(131);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: vm(49) auto vm(46) auto;
    border-radius: vm(10);
    min-height: auto;
  }
}
.head-portrait {
  width: 332px;
  height: 332px;
  position: absolute;
  top: -150px;
  left: -11px;
  border-radius: 100%;
  overflow: hidden;
  @include mediaS {
    width: vm(192);
    height: vm(200);
    position: absolute;
    top: vm(-61);
    left: vm(-42);
    overflow: hidden;
    border-radius: 0;
  }
}
.info {
  padding-top: 190px;
  > p {
    font-size: 18px;
    text-align: center;
    padding: 0 10px;
  }
  @include mediaS {
    min-width: vm(162);
    max-width: vm(175);
    padding: 0 0 0 0;
    > p {
      font-size: vm(14);
      text-align: left;
    }
  }
}
.info-text {
  font-weight: 600;
  font-size: 22px;
  @include mediaS {
    font-size: vm(16) !important;
  }
}

.info-text-name {
  @extend .info-text;
}

.card-2 {
  .info {
    @include mediaS {
      max-width: vm(160);
    }
  }
}
.card-3 {
  .info {
    @include mediaS {
      max-width: vm(190);
    }
  }
}
// 英文样式
.locale-en {
  &.box {
    @include mediaS {
      margin: vm(37) auto vm(46) auto;
    }
  }
  .info {

    @include mediaL{
      padding-top: 211px;
    }
  }
  .info-text {
    font-weight: 700;
    @include mediaS {
      line-height: vm(16);
    }
    @include mediaL {
      font-size: 18px;
      margin-bottom: 6px;
    }
  }
  .info-text-name {
    font-weight: 700;
    @include mediaS {
      line-height: vm(17);
    }
    @include mediaL {
      font-size: 22px;
    }
  }
  .info-p {
    @include mediaS {
      line-height: vm(16);
    }
    @include mediaL {
      font-size: 16px;
      line-height: 22px;
      opacity: 0.8;
      font-weight: normal;
    }
  }
}
