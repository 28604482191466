@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';
@import '~@/styles/config.scss';
@import '~@/styles/color.scss';

.container-box {
  overflow: hidden;
  @include mediaL {
    height: 1280px;
    position: relative;
    margin-bottom: 111px;
  }
  @include mediaS {
    height: vm(687);
    position: relative;
    margin: vm(14) 0 vm(30) 0;
  }
}
.content-bg {
  background: #f96665;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include mediaL {
    clip-path: polygon(0 11%, 100% 0, 100% 90%, 0% 100%);
  }
  @include mediaS {
    clip-path: polygon(0 7%, 100% 0, 100% 95%, 0% 100%);
  }
}
.container {
  position: relative;
  z-index: 1;
  @include mediaL {
    padding-top: 157px;
  }
  @include mediaS {
    padding-top: vm(78);
  }
}
.title {
  color: #fff;
  text-align: center;
}

.title-one {
  margin-bottom: 12px;
}
.title-two {
  margin-bottom: 46px;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.06em;
  @include mediaS {
    margin-bottom: vm(16);
    font-size: vm(16);
    line-height: vm(22);
  }
}
.title-three {
  width: 35%;
  margin: auto;
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: 0.06em;
  @include mediaS {
    width: vm(312);
    font-size: vm(14);
    line-height: vm(22);
    letter-spacing: 0.04em;
  }
  @include mediaXL {
    width: 32%;
  }
}
.teachers-card-box {
  width: 1100px;
  margin: auto;
  :global {
    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      @include mediaS {
        width: vm(10);
        height: vm(10);
      }
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
      background: rgb(255 255 255);
      margin: 0 20px;
      @include mediaS {
        margin: 0 10px;
      }
    }
  }
  @include mediaS {
    width: 100%;
  }
  --swiper-theme-color: #fff;
}
.button-box {
  text-align: center;
  margin-top: 20px;
  @include mediaS {
    margin-top: vm(6);
  }
}
.school-box {
  position: relative;
}
.school {
  width: 150px;
  height: 96px;
  display: inline-block;
  padding-top: 96px;
  background: url('/icon/location.png') top / cover no-repeat;
  background-size: 96px;
  font-size: 26px;
  color: #ffffff;
  text-align: center;
  &:nth-child(1) {
    position: absolute;
    top: -114px;
    left: 16px;
  }
  &:nth-child(2) {
    position: absolute;
    top: -38px;
    left: 330px;
  }
  &:nth-child(3) {
    position: absolute;
    top: -200px;
    right: -10px;
  }
  @include mediaS {
    width: vm(62);
    height: vm(35);
    padding-top: vm(35);
    background-size: vm(35);
    font-weight: 500;
    font-size: vm(12);
    line-height: vm(16);
    &:nth-child(1) {
      position: absolute;
      top: vm(5);
      left: vm(23);
    }
    &:nth-child(2) {
      position: absolute;
      top: 42px;
      left: 213px;
    }
    &:nth-child(3) {
      position: absolute;
      top: -30px;
      right: 5px;
    }
  }
  @include mediaXL {
    &:nth-child(1) {
      position: absolute;
      top: -176px;
      left: 187px;
    }
    &:nth-child(2) {
      position: absolute;
      top: 6px;
      left: 600px;
    }
    &:nth-child(3) {
      position: absolute;
      top: -240px;
      right: 200px;
    }
  }
}

// 英文样式
.locale-en {
  .button-box {
    @include mediaL{
      button {
        padding: 7px 17px 3px 17px;
      }
    }
    @include mediaS {
      button {
        padding: vm(6) vm(14) vm(3) vm(14);
      }
    }
  }
  .container {
    @include mediaS {
      height: 100%;
      box-sizing: border-box;
    }
  }
  .school-box {
    @include mediaS {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: vm(155);
    }
  }
  .school {
    @include mediaL {
      font-size: 18px;
      line-height: 22px;
      width: 137px;
      font-weight: 700;
    }
    @include mediaS {
      font-size: vm(12);
      line-height: vm(14);
    }
  }
  .title-three {
    opacity: 0.8;
    @include mediaL {
      width: 969px;
      font-size: 18px;
      line-height: 26px;
      font-weight: normal;
    }
    @include mediaS {
      width: vm(340);
      font-size: vm(14);
      line-height: vm(17);
      letter-spacing: initial;
    }
  }
  .title-two {
    @include mediaL {
      margin-bottom: 18px;
      font-size: 22px;
      line-height: 30px;
    }
    @include mediaS {
      width: vm(340);
      font-size: vm(14);
      line-height: vm(17);
      margin: 0 auto vm(10) auto;
    }
  }
}
