@import '~@/styles/mediaQuery.scss';

.container {
  width: 1240px;
  margin: 0 auto;

  @include mediaXL {
    width: 1295px;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 4px 4px;
    overflow: hidden;
  }

  th {
    width: 188px;
    height: 64px;
    font-size: 16px;
    line-height: 64px;
    background: #f0f0f0;
    border-radius: 18px 18px 0px 0px;
    font-weight: 600;
  }

  td {
    position: relative;
    vertical-align: middle;
  }

  tr {
    &:last-of-type {
      td {
        border-radius: 0px 0px 18px 18px;
      }
    }
  }
}

.text {
  display: flex;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ul {
    width: 100%;
    list-style: disc;
  }
}

.title {
  font-weight: 600;
  padding: 0 10px;
  text-align: center;
}

.features {
  position: relative;
  padding: 10px 0;

  ul {
    padding-left: 140px;
  }

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 59px;
    width: 12px;
    height: 14px;
    background: url('/images/home/coures-triangle.svg');
  }
}

.level {
  ul {
    padding-left: 100px;
  }

  p {
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    padding-left: 30px;
  }
}

.course-level {
  display: inline-block;
  margin-top: 10px;
}

.yellow {
  td {
    background: #ffba69;
  }
}

.red {
  td {
    background: #ff6666;
  }
}

.blue {
  td {
    background: #009cff;
  }
}
.green {
  background: #16d1c6;
}

.line {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 60px;
}

.arrow {
  position: absolute;
  right: 14px;
  bottom: 25px;
  pointer-events: none;
}

// 英文样式
.locale-en {
  .text {
    // text-align: center;
  }
  .features {
    p {
      padding: 0 34px;
    }
  }
  .text-books-td {
    width: 243px;
    p{
      padding: 0 20px;
    }
  }
  .course-systems-td {
    width: 188px;
  }
  .level-td {
    width: 556px;
  }
  .level {
    p {
      padding: 0 80px 0 30px;
    }
  }
}
