@import '~@/styles/viewport.scss';
@import '~@/styles/color.scss';
@import '~@/styles/mediaQuery.scss';

.container {
  text-align: center;
}

.content {
  display: inline-block;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #333;
  position: relative;
  padding-top: 26px;
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    height: 9px;
    background: $red;
  }

  @include mediaS {
    padding-top: 0;
    font-size: vm(18);
    line-height: vm(22);
    &:after {
      left: vm(-16);
      right: auto;
      top: vm(1);
      bottom: vm(1);
      width: vm(2);
      height: auto;
      transform: rotate(13deg);
    }
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      right: -16px;
      left: auto;
      background: $red;
      top: 1px;
      bottom: 1px;
      width: 2px;
      height: auto;
      transform: rotate(13deg);
    }
  }
}
.title-all-white {
  .content {
    color: #fff;
    &:after {
      background: #fff;
    }
    @include mediaS {
      &:before {
        background: #fff;
      }
    }
  }
}
