@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';

.container {
  width: 1276px;
  margin: 0 auto;
  // height: 480px;
  @include mediaL {
    margin: 0 auto 40px auto;
  }
  @include mediaS {
    width: 100%;
    height: vm(130);
    margin: 0 auto vm(10) auto;
  }
}

.title-container {
  position: relative;
  text-align: center;

  &:after {
    content: ' ';
    display: block;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    background: #000;
  }

  h2 {
    position: relative;
    z-index: 1;
    display: inline-block;
    font-size: 30px;
    line-height: 32px;
    padding: 0 147px;
    background: #ffffff;
    word-break: keep-all;
    white-space: nowrap;
    @include mediaS {
      font-size: vm(22);
      line-height: vm(22);
      padding: 0 vm(14);
    }
  }
  @include mediaS {
    width: vm(304);
    margin: auto;
  }
}

.content {
  padding: 70px 0;
  @include mediaS {
    padding: vm(24) 0;
  }
}

.line {
  flex: 1;
  display: flex;
  // justify-content: space-evenly;
  @include mediaL{
    margin-bottom: 12px;
  }
  @include mediaS {
    justify-content: space-between;
    margin-bottom: 0;
    width: vm(305);
    margin: 0 auto;
  }
  // &:first-child {
  //   // justify-content: space-between;
  // }

  // &:last-of-type {
  //   margin-bottom: 0;
  // }
  // > .item:first-of-type {
  //   text-align: left;
  // }
  // .block:first-of-type {
  //   .item {
  //     text-align: left;
  //   }
  // }

  // > .item:last-of-type {
  //   text-align: right;
  // }
  // .block:last-of-type {
  //   .item {
  //     text-align: right;
  //   }
  // }
}

.item {
  @include mediaL {

    margin-right: 12px;
    height: 160px;

  }
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFB;
  text-align: center;
  font-size: 0;
  @include mediaS {
    height: vm(56);
    // width: auto;
    margin-right: vm(5);
  }
  img {
    height: 101px;
    @include mediaS {
      height: vm(34);
      // width: auto;
    }
  }
}
.item:last-of-type {
  margin-right: 0;
}
